import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "icon", "count" ]
  static values = {
    reloadOnClick: String,
    removeFavoritesFromPageOnClick: String,
    domId: String
  }

  connect() {
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail

    // update the count
    var count = document.getElementById("favorites-count")
    count.innerHTML = xhr.response

    // toggle the icon
    this.iconTarget.checked = !this.iconTarget.checked


    if (this.reloadOnClickValue == "true") {
      location.reload();
    }

    if (this.removeFavoritesFromPageOnClickValue == "yes") {
      console.log(this.domIdValue)
      if (this.domIdValue) {
        //console.log("domId", document.getElementById(this.domIdValue))
        "domId", document.getElementById(this.domIdValue).remove()
      }
    }
  }
}
