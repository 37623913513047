// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Fetch from "fetch-rails"

import { Controller } from "stimulus"

export default class extends Controller {

  /*
  initialize() {
    // FIXME: clean this up
    const e = document.querySelector("#leadRequestSent")
    if (typeof(e) != 'undefined' && e != null) {
      const v = e.dataset.single
      if ( v == "true") {
        document.addEventListener('turbolinks:load', () => {
          $.fancybox.open({
            src: '#leadPopForm'
          });
        })
      } else {
          $.fancybox.close({
            src: '#leadPopForm'
          });
      }
    }
  }
  */

  connect() {
    const companyCount = this.setCompanyCount()
    if (companyCount > 0) {
      this.showBox()
    }
  }

  onPostSuccess() {
    // hideSpinner();
    gtag('event', 'click', {'event_category': 'Cart Lead Form', 'event_label': 'Cart Lead Form' })

    // GA4 Tracking
    let multipleCompanyDiv =document.querySelector("#leadPopFormCompanies");
    let companyDivs = multipleCompanyDiv.querySelectorAll(".requestCompanyItem");
    let items = [];
    companyDivs.forEach(function(companyDiv) {
      let item_id = companyDiv.getAttribute('data-company-id');
      let item_name = companyDiv.getAttribute('data-company-name');
      let item_variant = companyDiv.getAttribute('data-company-type');
      let item = {
        item_id: item_id,
        item_name: item_name,
        item_variant: item_variant,
        quantity: 1
      }
      items.push(item);
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "multiple-select",
      ecommerce: {
        form_origin: "multiple",
        items: items
      } 
    });
    let [data, status, xhr] = event.detail
    const leadPopForm = document.querySelector("#leadPopForm")
    leadPopForm.innerHTML = JSON.parse(xhr.responseText).html
    leadPopForm.scrollTo({
      top: 0,
      behavior: 'instant'
    })
    this.clearAllCompanies()
  }

  onPostError(event) {
    // hideSpinner();
    let [data, status, xhr] = event.detail
    const leadPopForm = document.querySelector("#leadPopForm")
    leadPopForm.innerHTML = JSON.parse(xhr.responseText).html
    leadPopForm.scrollTo({
      top: 0,
      behavior: 'instant'
    })

    if(data.show_checkbox_recaptcha){
      const recaptchaKey = document.querySelector('#multi-form #recaptcha-key').getAttribute('data-key');

      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.render(document.querySelector('#multi-form .g-recaptcha'), {sitekey: recaptchaKey})
      } else {
        console.log('Recaptcha not loaded')
      }
    }
  }

  setCompanyCount() {
    const companyCount = document.querySelectorAll('#requestInfoCompanies .imgContainer').length
    this.data.set("companyCount", companyCount)
    return companyCount
  }

  hideBox() {
    const box = document.querySelector("#requestInfoBox")
    box.style.display = 'none'
  }

  showBox() {
    const box = document.querySelector("#requestInfoBox")
    box.style.display = 'block'
  }

  toggleBox() {
    const companyCount = this.setCompanyCount()
    if (companyCount > 0) {
      this.showBox()
    } else {
      this.hideBox()
    }
  }

  toggleModal() {
    const companyCount = this.setCompanyCount()
    if (companyCount < 2) {
      $.fancybox.close()
    }
  }

  /*
   * - AJAX call to add company to cookie
   * - AJAX method handles opening box
   */
  createCompany(event) {
    const url = event.target.dataset.url
    let data = null
    let divNode = document.getElementById("search_filters_form")
    if (divNode) {
      let form = document.createElement("form")
      let inputNodes = divNode.getElementsByTagName('INPUT')
      Array.from(inputNodes).forEach(element => {
        form.appendChild(element.cloneNode())
      })
      data = new FormData(form)
    }
    this.requestInfoAjax('POST', url, data)
  }

  /*
   * - AJAX call to delete company from cookie
   * - AJAX method handles closing box if no companies listed
   * - makes sure that company listing checkbox is unchecked
   */
  deleteCompany(event) {
    const url = event.target.dataset.url
    this.requestInfoAjax('DELETE', url)
  }

  deleteAndUncheckCompany(event) {
    event.preventDefault()
    // Reverse the add-to-cart functionality:
    // - Delete the company from the session on the server
    // - Hide the "Added" message on the company listing
    // - Show the "Request Pricing" button
    const companyId = parseInt(event.target.dataset.companyId)

    if (companyId) {
      // console.log("companyId", companyId)
      const hiddenId = `company-added-${companyId}`
      // console.log("hiddenId", hiddenId)
      const hiddenDiv = document.getElementById(hiddenId)
      if (hiddenDiv) {
        // console.log("hiddenDiv", hiddenDiv)
        hiddenDiv.style.display = "none"
        // console.log("hiddenDiv", hiddenDiv)
      }
      const buttonId = `company-${companyId}-button`
      const button = document.getElementById(buttonId)
      if (button) {
        button.style.display = "block"
      }
      this.deleteCompany(event)
    }
  }

  deleteSelfAndCompany(event) {
    const div = event.target.closest("div.imgContainer")
    div.remove()
    this.deleteCompany(event)
    this.toggleModal()
  }

  clearAllCompanies() {
    const url = '/request_info'
    // Removes companies from cookie and closes the request box
    this.requestInfoAjax('DELETE', url)

    // Show all "Request Pricing" buttons
    var requestPricingButtons = document.querySelectorAll(".request-pricing-button")
    for (var i = 0; i< requestPricingButtons.length; i++) {
      if (requestPricingButtons[i].style.display == "none") {
        requestPricingButtons[i].style.display = "block"
      }
    }
    // Hide all "Added" divs
    var cartAddedDivs = document.querySelectorAll(".cartAdded")
    for (var i = 0; i< cartAddedDivs.length; i++) {
      if (cartAddedDivs[i].style.display == "block") {
        cartAddedDivs[i].style.display = "none"
      }
    }

  }

  activateRequestInfoBox(event) {
    event.preventDefault()
    window.item = event.target
    if (event.target.tagName == 'A') {
      this.createCompany(event)
      event.target.style.display = "none"
      let companyId = item.dataset.companyId
      // console.log("companyId", companyId)
      let hiddenId = "company-added-" + companyId
      // console.log("hiddenId", hiddenId)
      let hiddenDiv = document.getElementById(hiddenId)
      // console.log("hiddenDiv", hiddenDiv)
      hiddenDiv.style.display = "block"
      // console.log("hiddenDiv", hiddenDiv)
    } else {
      this.deleteCompany(event)
    }
  }

  openLeadPopForm(event) {
    const url = event.target.dataset.url

    Rails.ajax({
      url: url,
      type: 'POST',
      dataType: 'html',
      success: function(response) {
        // DEBUG
        // console.log(response) // response in json
        
        const toFill = document.querySelector("#leadPopForm")
        toFill.innerHTML = response.html
      },
      error: function(response) {
        console.error('error => ', response)
      }
    })

    $.fancybox.open({
      src: '#leadPopForm',
      toolbar: true,
      buttons: ["close"]
		});
  }

  requestInfoAjax(type, url, data=null) {
    let me = this

    Rails.ajax({
      url: url,
      type: type,
      data: data,
      dataType: 'html',
      success: function(response) {
        // DEBUG
        // console.log(response) // response in json

        let companyContent = document.querySelector("#requestInfoCompanies")
        companyContent.innerHTML = response.html
        me.toggleBox()
      },
      error: function(response) {
        console.error('error => ', response)
      }
    })
  }
}
